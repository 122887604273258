// Import the router
import { createRouter, createWebHistory } from "vue-router";
import { firebaseAuth } from "@/firebase/firebase";
import { getAnalytics, logEvent } from "firebase/analytics";

// Define the routes
const routes = [
    {
        path: "/",
        component: () => import("@/views/HomeView.vue"),
        name: "Home"
    },
    {
        path: "/set-explorer",
        component: () => import("@/views/SetExplorer.vue"),
        name: "SetExplorer"
    },
    {
        path: "/pulls/:id/dashboard",
        component: () => import("@/views/PullControlView.vue"),
        name: "Pull"
    },
    {
        path: "/landing",
        component: () => import("@/views/LandingPage.vue"),
        name: "Landing"
    },
    {
        path: "/user/:userID/pull/:pullID/overlay",
        component: () => import("@/views/StreamView.vue"),
        name: "StreamOverlay"
    },
    {
        path: "/user/:userID/pull/:pullID/share",
        component: () => import("@/views/ShareView.vue"),
        name: "ShareView"
    },
    {
        path: "/login",
        component: () => import("@/views/LoginView.vue"),
        name: "Login"
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/"
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const user = firebaseAuth.currentUser;

    // Allow access to the StreamOverlay path without authentication
    if (to.name === "StreamOverlay" || to.name === "ShareView") {
        next();
        return;
    }

    if (to.path === "/login" || to.path === "/landing") {
        next();
        return;
    }

    if (!user) {
        localStorage.setItem("redirectAfterLogin", to.fullPath);
        next({ name: "Login" });
        return;
    }

    next();
});

router.afterEach((to) => {
    logEvent(getAnalytics(), "page_view", {
        page_path: to.fullPath,
        page_title: to.name
    });
});

export default router;
