// store.js
import { defineStore } from "pinia";
import { firebaseAuth } from "@/firebase/firebase";
import { getDocuments, deleteDocument, createDocument } from "@/firebase/db";

export const useMainStore = defineStore("main", {
    state: () => ({
        loading: true,
        pulls: [],
        sets: [],
        setMapping: {}
    }),

    getters: {},

    actions: {
        setLoading(loading) {
            this.loading = loading;
        },
        async signOut() {
            await firebaseAuth.signOut();
            this.pulls = [];
        },
        setPulls(pulls) {
            this.pulls = pulls.sort(
                (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            );
        },
        async addPull(pull) {
            const userid = firebaseAuth.currentUser?.uid;
            if (userid) {
                const newPullID = await createDocument(
                    `users/${userid}/pulls`,
                    pull
                );
                pull.id = newPullID;
                this.pulls = [pull, ...this.pulls].sort(
                    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                );
                return newPullID;
            }
        },
        async fetchPulls() {
            console.log("fetchPulls");
            const userid = firebaseAuth.currentUser?.uid;
            if (userid) {
                try {
                    const pulls = await getDocuments(`users/${userid}/pulls`);
                    this.setPulls(
                        pulls.sort(
                            (a, b) =>
                                new Date(b.timestamp) - new Date(a.timestamp)
                        )
                    );
                } catch (error) {
                    console.error("Error fetching pulls", error);
                }
            }
        },
        async deletePull(pullId) {
            this.pulls = this.pulls.filter((pull) => pull.id !== pullId);

            const userid = firebaseAuth.currentUser?.uid;
            if (userid) {
                await deleteDocument(`users/${userid}/pulls`, pullId);
            }
        },
        async fetchSets() {
            if (this.sets.length) return;

            const response = await fetch("https://api.scryfall.com/sets/");
            const data = await response.json();
            const sets = data.data;

            // Create a mapping from set codes to sets
            const setMapping = sets.reduce((acc, set) => {
                acc[set.code] = { ...set, children: [] };
                return acc;
            }, {});

            // Build the set hierarchy considering both parent_set_code and block_code
            sets.forEach((set) => {
                const parentCode = set.parent_set_code || set.block_code;
                if (parentCode && setMapping[parentCode]) {
                    if (!setMapping[parentCode].children) {
                        setMapping[parentCode].children = [];
                    }
                    setMapping[parentCode].children.push(set);
                }
            });

            // Function to recursively find all related sets
            const getAllRelatedSets = (setCode, relatedSets = new Set()) => {
                if (!setMapping[setCode] || relatedSets.has(setCode)) {
                    return Array.from(relatedSets);
                }

                relatedSets.add(setCode);

                const parentSetCode = setMapping[setCode].parent_set_code;
                if (parentSetCode) {
                    getAllRelatedSets(parentSetCode, relatedSets);
                }

                const childSets = sets.filter(
                    (set) => set.parent_set_code === setCode
                );
                childSets.forEach((childSet) => {
                    getAllRelatedSets(childSet.code, relatedSets);
                });

                return Array.from(relatedSets);
            };

            // Prepare sets array including child count and related sets
            this.sets = Object.values(setMapping)
                .filter((set) => !set.parent_set_code)
                .map((set) => ({
                    ...set,
                    childCount: set.children.length,
                    relatedSets: getAllRelatedSets(set.code)
                }));

            this.setMapping = setMapping;
        },
        async fetchCardDetails(set, collectorNumber) {
            const url = `https://api.scryfall.com/cards/${set}/${collectorNumber}`;
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return await response.json();
            } catch (error) {
                console.error("Error fetching card details", error);
                throw error;
            }
        }
    }
});
