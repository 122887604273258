import { db } from "./firebase.js";
import {
    collection,
    addDoc,
    getDocs,
    doc,
    getDoc,
    setDoc,
    deleteDoc
} from "firebase/firestore";

// Generic function to create a document in any collection
export const createDocument = async (collectionName, data) => {
    try {
        const docRef = await addDoc(collection(db, collectionName), data);
        // console.log("Document written with ID: ", docRef.id);
        return docRef.id;
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};

export const createDocumentWithId = async (collectionName, id, data) => {
    try {
        // console.log("Creating document with ID: ", id);
        await setDoc(doc(db, collectionName, id), data);
        // console.log("Document written with ID: ", id);
        return id;
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};

// Generic function to get all documents from any collection
export const getDocuments = async (collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    let documents = [];
    querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, ...doc.data() });
    });
    return documents;
};

// Generic function to get a specific document from any collection
export const getDocument = async (collectionName, id) => {
    const docRef = doc(db, collectionName, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
    } else {
        // console.log("No such document!");
    }
};

// Generic function to check if a given document exists
export const documentExists = async (collectionName, id) => {
    const docRef = doc(db, collectionName, id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists();
};

// Generic function to update a document in any collection
export const updateDocument = async (collectionName, id, data) => {
    try {
        await setDoc(doc(db, collectionName, id), data);
        // console.log("Document successfully updated!");
    } catch (e) {
        console.error("Error updating document: ", e);
    }
};

// Generic function to delete a document from any collection
export const deleteDocument = async (collectionName, id) => {
    try {
        await deleteDoc(doc(db, collectionName, id));
        // console.log("Document successfully deleted!");
    } catch (e) {
        console.error("Error removing document: ", e);
    }
};
