// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

import {
    getFirestore,
    initializeFirestore,
    persistentLocalCache,
    persistentMultipleTabManager
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCcbNVBTxAt6IaT0pvIL5pHcL1JngI-jtU",
    authDomain: "mtg-pack-appraisal.firebaseapp.com",
    projectId: "mtg-pack-appraisal",
    storageBucket: "mtg-pack-appraisal.appspot.com",
    messagingSenderId: "1029484243777",
    appId: "1:1029484243777:web:5cdd834a4e7bb3b69d8fc0",
    measurementId: "G-PD1KD088WH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeFirestore(app, {
    localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager()
    })
});

const analytics = getAnalytics(app);

analytics.app.automaticDataCollectionEnabled = true;

export const firebaseAuth = getAuth(app);
export const db = getFirestore();
