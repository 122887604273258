import { createApp } from "vue";
import VueLazyLoad from "vue3-lazyload";
import { createPinia } from "pinia";
import App from "./App.vue";

// import VueGtag from "vue-gtag";
import VueGtag from "vue-gtag";

// styles?
import "./index.css";

// Import the router
import router from "@/router/router";

const pinia = createPinia();
const app = createApp(App);

import { db } from "@/firebase/firebase";
app.config.globalProperties.$firebaseDB = db;

app.use(VueLazyLoad, {
    // options...
});
app.use(router);
app.use(pinia);
app.use(
    VueGtag,

    {
        appName: "PullValue",
        pageTrackerScreenviewEnabled: true,
        config: { id: "G-PD1KD088WH" }
    },
    router
);
app.mount("#app");
