<template>
    <Loading loader="bars" :isFullPage="true" :active="loading" :opacity="1" />
    <RouterView />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { useMainStore } from "@/store";
import { mapState } from "pinia";

export default {
    name: "App",
    computed: {
        ...mapState(useMainStore, ["loading"])
    },
    components: {
        Loading
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
